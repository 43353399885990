import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './user-info-chat.module.scss';

export const SekeletonUserInfoChat: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatarWrap}>
                {/* Скелетон для аватара */}
                <Skeleton
                    circle={true}
                    height={41}
                    width={41}
                />
            </div>
            <div className={styles.details}>
                {/* Скелетон для имени и возраста */}
                <Skeleton
                    width={120}
                    height={20}
                />
            </div>
        </div>
    );
};
