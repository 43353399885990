import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetLikeMe = () => {
    return useQuery({
        queryKey: ['like_me'],
        queryFn: profilesApi.getLikeMe,
    });
};
