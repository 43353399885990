import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import WebApp from '@twa-dev/sdk';
import { Outlet, useNavigate } from 'react-router-dom';

export const TgProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (WebApp.BackButton.isVisible) {
            WebApp.onEvent('backButtonClicked', handleBack);
        }
        return () => {
            WebApp.offEvent('backButtonClicked', handleBack);
        };
    }, [handleBack]);
    return <Outlet /> || children;
};
