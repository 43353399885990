import { HeaderMenu } from '@/components/header-menu';
import styles from './settings.module.scss';
import { SettingsList } from '@/components/settings-list';
import { Container } from '@/ui-kit';
export const Settings = () => {
    return (
        <div className={styles.settings}>
            <HeaderMenu
                title="Настройки"
                marginBottom={20}
            />
            <Container>
                <SettingsList />
            </Container>
        </div>
    );
};
