import { ChatMessageItemSkeleton } from '../chat-message-item/';

export const SkeletonChatViewport = () => {
    return (
        <div className="skeleton-chat-viewport">
            <ChatMessageItemSkeleton />
            <ChatMessageItemSkeleton isMyMessage />
            <ChatMessageItemSkeleton />
            <ChatMessageItemSkeleton isMyMessage />
            <ChatMessageItemSkeleton />
            <ChatMessageItemSkeleton isMyMessage />
            <ChatMessageItemSkeleton />
            <ChatMessageItemSkeleton isMyMessage />
            <ChatMessageItemSkeleton />
            <ChatMessageItemSkeleton isMyMessage />
            <ChatMessageItemSkeleton />
        </div>
    );
};
