import React, { useEffect, useState } from 'react';
import styles from './confirm.module.scss';
import clsx from 'clsx';
import { Typography } from '../typography';
import { Button } from '../button';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    title?: string;
    text?: string;
    buttonTextSubmit?: string;
    buttonTextClose?: string;
    isLoading?: boolean;
    onConfirm?: () => void;
}

const ANIMATION_DELAY = 250;

export const Confirm: React.FC<Props> = ({
    isOpen,
    onClose,
    title,
    text,
    buttonTextSubmit = 'Удалить',
    buttonTextClose = 'Отмена',
    isLoading,
    onConfirm,
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        if (isOpen) {
            setVisible(true);
        } else {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setVisible(false);
            }, ANIMATION_DELAY);
        }
    }, [isOpen]);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={clsx(styles.overlay, isClosing && styles.closing)}
            onClick={onClose}
        >
            <div
                className={clsx(styles.confirm, isClosing && styles.closing)}
                onClick={(e) => e.stopPropagation()} // Останавливает закрытие модалки при клике внутри нее
            >
                <div className={styles.info}>
                    {title && (
                        <Typography
                            variant="p"
                            fontWeight="semibold"
                            className={styles.title}
                            fontFamily="SFProText"
                            center
                        >
                            {title}
                        </Typography>
                    )}
                    {text && (
                        <Typography
                            variant="p"
                            typeColor="black"
                            className={styles.text}
                            fontFamily="SFProText"
                            center
                        >
                            {text}
                        </Typography>
                    )}
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        textColor="black"
                        className={clsx(styles.button, styles.closeButton)}
                        onClick={onClose}
                    >
                        {buttonTextClose}
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className={styles.button}
                        onClick={onConfirm}
                    >
                        {buttonTextSubmit}
                    </Button>
                </div>
            </div>
        </div>
    );
};
