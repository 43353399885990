import { Avatar, Button, Typography } from '@/ui-kit';
import styles from './avatar-profile.module.scss';
import { PencilIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';
interface Props {
    avatar?: string;
    name?: string;
    // email?: string;
}
export const AvatarProfile: React.FC<Props> = ({ avatar, name }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatarWrapper}>
                <Avatar
                    src={avatar}
                    size={120}
                />
                {/* <span className={styles.status}></span> */}
            </div>
            <Typography
                className={styles.name}
                typeColor="black"
                center
            >
                {name}
            </Typography>
            <Button
                className={styles.button}
                textColor="black"
                to={routesApp.profileEdit}
                isLink
            >
                <PencilIcon />
                <span>Редактировать</span>
            </Button>
            {/* {email && (
                <a
                    className={styles.email}
                    href={`mailto:` + email}
                >
                    {email}
                </a>
            )} */}
        </div>
    );
};
