import React, { PropsWithChildren } from 'react';
import styles from './badge-premium.module.scss';
import badgeHeart from '@/assets/images/subscription/badge-heart.png';
import { Typography } from '@/ui-kit';
import clsx from 'clsx';
interface Props {
    title?: string;
    description?: string;
    type?: 'row' | 'column';
}

export const BadgePremium: React.FC<PropsWithChildren<Props>> = ({
    title = 'Просмотр лайков',
    description,
    type = 'row',
    children,
}) => {
    return (
        <div className={clsx(styles.wrapper, styles[type])}>
            <div className={styles.dekor}>
                <img
                    src={badgeHeart}
                    alt="badgeHeart"
                />
            </div>
            <div className={styles.info}>
                {title && (
                    <Typography
                        className={styles.title}
                        fontFamily="SFProText"
                        fontWeight="semibold"
                        center={type === 'column'}
                    >
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography
                        fontFamily="SFProText"
                        className={styles.description}
                        center={type === 'column'}
                    >
                        {description}
                    </Typography>
                )}
                {children}
            </div>
        </div>
    );
};
