import { useAuth } from '@/store/useAuth';
import { useUpdateUserData } from '@/hooks/use-update-profile';
import { generatePath, useNavigate } from 'react-router';
import { routesApp } from '@/constans/routes';
import { useCallback, useEffect } from 'react';
import { FourthStepRegistartionForm } from './components/fourth-step-registartion-form';
import { UploadPhotoRegistartionForm } from './components/upload-photo-registartion-form';
import { MainInfoRegistartionForm } from './components/main-info-registartion-form';
interface Props {
    step?: string;
    isLoadingOncePage?: boolean;
    onChangeIsLoadingPage?: (bol: boolean) => void;
}
export const FormRegistration: React.FC<Props> = ({ step, isLoadingOncePage, onChangeIsLoadingPage }) => {
    const userData = useAuth((state) => state.getUser());
    const { mutateAsync: updateUserData } = useUpdateUserData();
    const handleUpdateUserData = async (data: any) => {
        if (!userData) return;
        await updateUserData(data);
    };

    const navigateToNextStep = useNavigate();
    const nextStep = useCallback(
        (step: string) => navigateToNextStep(generatePath(routesApp.registration, { stepNumer: step })),
        [navigateToNextStep],
    );
    useEffect(() => {
        if (userData && isLoadingOncePage) {
            onChangeIsLoadingPage?.(false);
            // Проверяем, на каком шаге нужно продолжить регистрацию
            if (!userData?.public_name || !userData?.gender || !userData?.dob || !userData?.region) {
                nextStep('1');
                return;
            } else if (!userData?.photos.length) {
                nextStep('2');
                return;
            } else if (!userData?.looks_for || !userData?.min_age || !userData?.max_age || !userData?.purpose) {
                nextStep('3');
                return;
            } else {
                // navigateToNextStep(routesApp.browse);
                nextStep('3');
            }
        }
    }, []);

    switch (step) {
        case '1':
            return (
                <MainInfoRegistartionForm
                    userData={userData}
                    onUpdateUserData={handleUpdateUserData}
                    nextStep={() => nextStep('2')}
                />
            );
        case '2':
            return <UploadPhotoRegistartionForm userData={userData} />;
        case '3':
            return (
                <FourthStepRegistartionForm
                    userData={userData}
                    onUpdateUserData={handleUpdateUserData}
                    nextStep={() => navigateToNextStep(routesApp.browse)}
                />
            );
        default:
            return <>Error 404</>;
    }
};
