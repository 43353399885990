import { ApiClient } from '@/services/api.service';
import { Tariff } from '@/types/tariff';

interface SettingsDto {
    chat_url: string;
}
interface InvoiceDto {
    ok: boolean;
    result: string;
}
class SettingsApi {
    async getSettings() {
        const data = await ApiClient.get<SettingsDto>('/settings');
        return data;
    }

    async getTariffs() {
        const data = await ApiClient.get<Tariff[]>('/tariffs/');
        return data;
    }
    async getInvoice(tariffId: number) {
        const data = await ApiClient.get<InvoiceDto>('/get_invoice/', {
            params: { tariff: tariffId },
        });
        return data;
    }
}

export const settingsApi = new SettingsApi();
