import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetMatches = () => {
    return useQuery({
        queryKey: ['matches'],
        queryFn: profilesApi.getMatches,
    });
};
