const TOKEN_KEY = 'token';

class JwtService {
    getToken() {
        return window.sessionStorage.getItem(TOKEN_KEY);
    }

    initToken() {
        const searchParams = new URLSearchParams(window.location.search);
        const paramsToken = searchParams.get(TOKEN_KEY);
        if (paramsToken) {
            this.setToken(paramsToken as string);
        } else {
            if (process.env.NODE_ENV !== 'production' && !this.getToken() && process.env.REACT_APP_TEST_TOKEN_KEY) {
                this.setToken(process.env.REACT_APP_TEST_TOKEN_KEY);
            }
        }
    }

    setToken(token: string) {
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    destroyToken() {
        window.sessionStorage.removeItem(TOKEN_KEY);
    }
}

export const jwtService = new JwtService();
