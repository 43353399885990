// import { CardSwiperUser } from '@/components/card-swipe-user';
import { useGetProfilesAll } from '@/hooks/use-get-profiles-all';
import { Spiner } from '@/ui-kit/';
import { CardSwiperUserNew } from '@/components/card-swiper-user-new';

export function Browse() {
    const { data, isFetching, refetch } = useGetProfilesAll();
    return isFetching ? (
        <Spiner />
    ) : (
        <>
            {/*<CardSwiperUser*/}
            {/*    users={data?.data}*/}
            {/*    refetchData={refetch}*/}
            {/*/>*/}
            <CardSwiperUserNew
                users={data?.data}
                refetchData={refetch}
            />
        </>
    );
}
