import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './couples-item.module.scss';
import { Avatar, Typography } from '@/ui-kit';
import { generatePath, Link } from 'react-router-dom';
import { routesApp } from '@/constans/routes';
interface Props {
    className?: string;
    ellipse?: boolean;
    info?: string | null;
    avatar?: string | null;
    onClick?: () => void;
    href?: string;
}
export const CouplesItem: React.FC<PropsWithChildren<Props>> = ({
    className,
    ellipse,
    info,
    avatar,
    children,
    href,
    onClick,
}) => {
    return (
        <Link
            to={href ?? '#'}
            className={clsx(className, styles.coupleItem)}
            onClick={onClick}
        >
            <div className={clsx(styles.avatar, ellipse && styles.ellipse)}>
                <Avatar
                    src={avatar}
                    alt="avatar"
                />
                {children}
            </div>
            {info && (
                <Typography
                    typeColor="brown"
                    className={styles.info}
                    fontWeight="bold"
                    center
                >
                    {info}
                </Typography>
            )}
        </Link>
    );
};
