import React, { useState } from 'react';
import styles from './subscription.module.scss';
import { Button, Container, Typography } from '@/ui-kit';
import { BadgePremium } from '@/components/badge-premium';
import { useGetTariffs } from '@/hooks/use-get-tariffs';
import { useGetInvoice } from '@/hooks/use-get-invoice';
import WebApp from '@twa-dev/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { showToast } from '@/utils/show-toast';
import { useNavigate } from 'react-router-dom';
import { routesApp } from '@/constans/routes';
import { PremiumList } from '@/components/premium-list';

export const Subscription: React.FC = () => {
    const { data } = useGetTariffs();
    const { mutateAsync: getInvoice, isPending } = useGetInvoice();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [tariffActiveId, setTariffActiveId] = useState(2);
    const handleClickSelectPremiumItem = (id: number) => {
        setTariffActiveId(id);
    };

    const handlePayment = async () => {
        if (!tariffActiveId) return;
        try {
            const data = await getInvoice(tariffActiveId);
            if (!data.data.ok) return;
            WebApp.openInvoice(data.data.result, (status) => {
                if (status === 'failed') {
                    throw new Error('Произошла ошибка оплаты попробуйте позже');
                }
                if (status === 'paid') {
                    navigate(routesApp.profile);
                    queryClient.invalidateQueries({ queryKey: ['profile'] });
                }
            });
        } catch (error: any) {
            console.error(error);
            const message = error?.message || 'Ошибка попробуйте позже';
            showToast('error', message);
        }
    };

    return (
        <section className={styles.section}>
            <div className={styles.wrapper}>
                <div className={styles.dekor}>
                    <div className={styles.dekorLight} />
                </div>
                <div className={styles.dekorHeart} />
                <div className={styles.info}>
                    <Typography
                        center
                        fontFamily="SFProDisplay"
                        fontWeight="bold"
                        className={styles.title}
                    >
                        Proximity Premium
                    </Typography>
                    <Typography
                        center
                        fontFamily="SFProText"
                        className={styles.description}
                    >
                        С Premium вы найдете партнера в&nbsp;два раза быстрее
                    </Typography>
                </div>
                <PremiumList
                    tariffActiveId={tariffActiveId}
                    onChangeTariff={(id) => handleClickSelectPremiumItem(id)}
                    tariffs={data?.data}
                />
                <Container className={styles.badgeContainer}>
                    <Typography
                        fontWeight="bold"
                        fontFamily="SFProText"
                        className={styles.badgeText}
                    >
                        Станет доступным:
                    </Typography>
                    <BadgePremium description="Смотрите анкеты тех, кому вы понравились&nbsp;и&nbsp;отвечайте взаимностью" />
                    <Button
                        variant="stretched"
                        textColor="black"
                        className={styles.button}
                        onClick={handlePayment}
                        isLoading={isPending}
                    >
                        Купить
                    </Button>
                </Container>
            </div>
        </section>
    );
};
