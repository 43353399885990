import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './native-date-picker.scss';
import clsx from 'clsx';
import { Typography } from '@/ui-kit';

export const NativeDatePicker: React.FC<any> = ({ name, placeholder, isError }) => {
    const { control } = useFormContext();

    // Ограничения для выбора даты — не более 18 лет назад
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    return (
        <div className="datepicker">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                    return (
                        <div className="picker__wrapper">
                            <Typography
                                fontWeight="medium"
                                fontFamily="SFProText"
                                className="datepicker__button-text"
                            >
                                Дата рождения
                            </Typography>
                            <input
                                type="date"
                                value={value || ''}
                                onChange={(e) => onChange(e.target.value)}
                                max={eighteenYearsAgo.toISOString().split('T')[0]}
                                min="1900-01-01"
                                placeholder={placeholder}
                                className={clsx('datepicker__input', isError && 'datepicker__input--error')}
                            />
                        </div>
                    );
                }}
            />
        </div>
    );
};
