import React, { forwardRef, TextareaHTMLAttributes, useState } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styles from './textarea.module.scss';
import clsx from 'clsx';
import { Typography } from '@/ui-kit';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    maxLength: number;
    error?: string;
    height?: number;
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
    ({ label, maxLength, error, className, required, onChange, defaultValue, ...rest }, ref) => {
        const [charCount, setCharCount] = useState((defaultValue as string)?.length ?? 0);

        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setCharCount(e.target.value.length);
            if (onChange && e.target.value.length <= maxLength) {
                onChange(e);
            }
        };

        return (
            <div className={styles.container}>
                {label && (
                    <Typography
                        fontWeight="semibold"
                        variant="label"
                        className={clsx(required && styles.required, styles.label, error && styles.error)}
                    >
                        {label}
                    </Typography>
                )}
                <TextareaAutosize
                    ref={ref}
                    defaultValue={defaultValue}
                    className={clsx(
                        styles.textarea,
                        {
                            [styles.error]: charCount > maxLength || error,
                        },
                        className,
                    )}
                    maxLength={maxLength}
                    // rows={4}
                    onChange={handleChange}
                    {...(rest as TextareaAutosizeProps)}
                />
                <Typography
                    typeColor="gray"
                    className={clsx(styles.characterCount, {
                        [styles.error]: charCount > maxLength,
                    })}
                >
                    {charCount} / {maxLength}
                </Typography>
                {/* <FieldError message={error} /> */}
            </div>
        );
    },
);

Textarea.displayName = 'Textarea';
