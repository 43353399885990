import { useQuery } from '@tanstack/react-query';

import { settingsApi } from '@/api';

export const useGetTariffs = () => {
    return useQuery({
        queryKey: ['tariffs'],
        queryFn: settingsApi.getTariffs,
    });
};
