import React from 'react';
import styles from './new-couples.module.scss';

import { CouplesItemLike } from './components/couples-item-like';
import { couplesItems } from '@/mocks/couples-items';
import { Container, Typography } from '@/ui-kit';
import { Profile } from '@/types/profile';
import { useCurrentChatInfo } from '@/store/useCurrentChatInfo';
import HeartImage from '@/assets/images/subscription/badge-heart.png';
import { generatePath } from 'react-router-dom';
import { routesApp } from '@/constans/routes';
import { CouplesItem, SkeletonCouplesItem } from './components/couples-item';
interface Props {
    isLoading: boolean;
    matches?: Profile[];
}
export const NewCouples: React.FC<Props> = ({ isLoading, matches }) => {
    const handleCurrentChat = useCurrentChatInfo((state) => state.setCurrentChat);

    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={styles.label}>
                    <Typography
                        typeColor="gray"
                        uppercase
                        variant="span"
                    >
                        Новые пары
                    </Typography>
                </div>
            </Container>
            <div className={styles.list}>
                {isLoading ? (
                    Array.from({ length: 5 }).map((_, index) => <SkeletonCouplesItem key={index} />)
                ) : (
                    <>
                        <CouplesItemLike
                            avatar={HeartImage}
                            count={couplesItems.length}
                            href={routesApp.liked}
                        />
                        {matches?.map((match) => {
                            return (
                                <CouplesItem
                                    key={match.id}
                                    onClick={() => handleCurrentChat(match)}
                                    // ellipse={match.}
                                    href={generatePath(routesApp.chat, { id: String(match.id) })}
                                    info={match?.public_name}
                                    avatar={match.photos.length > 0 ? match.photos[0].thumb_url : null}
                                />
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};
