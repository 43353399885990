import React from 'react';
import styles from './profile-edit.module.scss';
import { FormProfileEdit } from '@/components/form-profile-edit';
import { Container, Spiner } from '@/ui-kit';
import { useAuth } from '@/store/useAuth';
interface Props {}

export const ProfileEdit: React.FC<Props> = () => {
    const userData = useAuth((state) => state.getUser());
    return (
        <div className={styles.wrapper}>
            <Container>{!userData ? <Spiner /> : <FormProfileEdit userData={userData} />}</Container>
        </div>
    );
};
