import React from 'react';
import { Typography } from '@/ui-kit';
import styles from './step-label.module.scss';
interface Props {
    stepNumber: number | string;
    stepsCount?: number;
}
export const StepLabel: React.FC<Props> = ({ stepNumber, stepsCount = 3 }) => {
    return (
        <div className={styles.wrapperStep}>
            <Typography
                typeColor="gray"
                className={styles.text}
            >
                <b>{stepNumber}</b> из {stepsCount}
            </Typography>
        </div>
    );
};
