import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './header-menu.module.scss';
import { ArrowBackUpIcon } from '@/assets/icons';
import { Container, Typography } from '@/ui-kit';
import clsx from 'clsx';
interface Props {
    title?: string | React.ReactNode;
    backLink?: string;
    className?: string;
    isfixed?: boolean;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    marginBottom?: number;
    headerBg?: 'white' | 'gray';
}

export const HeaderMenu: React.FC<PropsWithChildren<Props>> = ({
    title,
    backLink,
    className,
    isfixed,
    children,
    marginBottom,
    prepend,
    append,
    headerBg,
}) => {
    return (
        <Container className={clsx(isfixed && styles.fixed, headerBg && styles[headerBg], className)}>
            <nav
                className={styles.nav}
                style={{ marginBottom: marginBottom }}
            >
                {backLink && (
                    <div className={styles.prependContainer}>
                        <Link
                            className={styles.back}
                            to={backLink}
                        >
                            <ArrowBackUpIcon
                                width={18}
                                height={18}
                            />
                        </Link>
                    </div>
                )}

                {prepend && !backLink && <div className={styles.prependContainer}>{prepend}</div>}

                <div className={styles.childContainer}>
                    {children
                        ? children
                        : title && (
                              <Typography
                                  variant="p"
                                  fontWeight="medium"
                                  className={styles.title}
                              >
                                  {title}
                              </Typography>
                          )}
                </div>

                {append && <div className={styles.appendContainer}>{append}</div>}
            </nav>
        </Container>
    );
};
