import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetChats = () => {
    return useQuery({
        queryKey: ['chats'],
        queryFn: profilesApi.getChats,
    });
};
