import { settingsApi } from '@/api';
import { showToast } from '@/utils/show-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useGetInvoice = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: settingsApi.getInvoice,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profiles'] });
        },
        onError: (error) => {
            console.error('Error complain profile:', error);
            showToast('error', 'Ошибка отправки заявки');
        },
    });

    return mutation;
};
