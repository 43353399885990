import React from 'react';
import styles from './liked-item.module.scss';
import { ButtonsLikeDislikeCard } from '../buttons-like-dislike-card';
import { VoteProfileDto } from '@/api/profiles';
import { Avatar, Typography } from '@/ui-kit';
import { Profile } from '@/types/profile';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { HeartIcon } from '@/assets/icons';
import { format, isToday, isYesterday } from 'date-fns';
import { ru } from 'date-fns/locale';

interface Props {
    img?: string | null;
    profile?: Profile;
    onVoteProfile?: (vote: VoteProfileDto['vote']) => void;
    isPaid: boolean;
}

export const LikedItem: React.FC<Props> = ({ profile, onVoteProfile, isPaid }) => {
    const handleVoteProfile = (vote: VoteProfileDto['vote']) => {
        if (onVoteProfile) {
            onVoteProfile(vote);
        }
    };

    const formatDate = (date: string): string => {
        if (isToday(date)) {
            return `сегодня в ${format(date, 'HH:mm', { locale: ru })}`;
        }

        if (isYesterday(date)) {
            return `вчера в ${format(date, 'HH:mm', { locale: ru })}`;
        }

        return format(date, "d MMMM 'в' HH:mm", { locale: ru });
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatar}>
                {isPaid ? (
                    <Avatar
                        size={64}
                        src={profile?.photos[0]?.thumb_url}
                        alt={profile?.public_name ?? 'avatar'}
                        borderRadius="26px"
                    />
                ) : (
                    <div className={styles.blurImageWrapper}>
                        <div className={styles.blurImage}>
                            <div className={styles.image}>
                                <Avatar
                                    size={64}
                                    src={profile?.photos[0]?.thumb_url}
                                    alt={profile?.public_name ?? 'avatar'}
                                    borderRadius="26px"
                                />
                            </div>

                            <HeartIcon
                                width={24}
                                height={24}
                                className={styles.heart}
                            />
                        </div>
                    </div>
                )}
                {profile?.is_online && <span className={styles.status}></span>}
            </div>
            <div className={styles.wrapInfo}>
                <div className={styles.info}>
                    <Typography
                        typeColor="brown"
                        className={styles.name}
                    >
                        {isPaid ? (
                            <span>
                                {' '}
                                {profile?.public_name}, {differenceInYearsResult(profile?.dob)}
                            </span>
                        ) : (
                            <span>Ты {profile?.gender === 'female' ? 'ей' : 'ему'} нравишься</span>
                        )}
                    </Typography>
                    <Typography
                        typeColor="gray"
                        className={styles.time}
                    >
                        {profile?.gender === 'female' ? 'Лайкнула' : 'Лайкнул'}{' '}
                        {profile?.created_at ? formatDate(profile.created_at) : ''}
                    </Typography>
                </div>
            </div>
            {isPaid && (
                <ButtonsLikeDislikeCard
                    onClickDislike={() => handleVoteProfile('dislike')}
                    onClickLike={() => handleVoteProfile('like')}
                />
            )}
        </div>
    );
};
