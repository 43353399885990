import { routesApp } from '@/constans/routes';
import { HeaderMenu } from '../header-menu';
import { SekeletonUserInfoChat, UserInfoChat } from '../user-info-chat';
import { Button, Confirm } from '@/ui-kit';
import { VerticalDotsIcon } from '@/assets/icons';
import styles from './header-chat.module.scss';
import { useState } from 'react';
import { ModalSettingsUserChat } from '../modal-settings-user-chat';
import { useNavigate, useParams } from 'react-router-dom';
import { Chat } from '@/types/chat';
import { SettingsData } from '../modal-settings-user-chat/settings-data';
import { useBlockedProfileById } from '@/hooks/use-blocked-profile-by-id';
import { useComplainProfileById } from '@/hooks/use-complain-profile-by-id';
import { useClearChatWhithUser } from '@/hooks/use-clear-chat-whith-user';

interface Props {
    typeColor?: 'white' | 'gray';
    profileChat?: Chat['profile'] | null;
    isLoading?: boolean;
    refetchMessages: () => void;
}

const initialModalConfirmContentState = {
    title: 'Удалить симпатию?',
    description: 'Диалог и взаимная симпатия исчезнут',
};

export const HeaderChat: React.FC<Props> = ({ typeColor = 'white', profileChat, isLoading, refetchMessages }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const { id } = useParams();
    const profileId = Number.isNaN(Number(id)) ? '' : Number(id);
    const { mutateAsync: blockedProfileById, isPending: isLoadingBlocked } = useBlockedProfileById();
    const { mutateAsync: clearChatById, isPending: isLoadingClearChat } = useClearChatWhithUser();
    const { mutateAsync: complainProfileById, isPending: isLoadingComplain } = useComplainProfileById();
    const [settingsData, setSettingsData] = useState<SettingsData['action']>();
    const navigate = useNavigate();
    const [confirmContent, setConfirmContent] = useState(initialModalConfirmContentState);

    const setModalConfirmContent = (settings: SettingsData['action']) => {
        switch (settings) {
            case 'block':
                setConfirmContent({
                    title: 'Вы действительно хотите заблокировать пользователя?',
                    description: 'Это действие нельзя отменить, вы подтверждаете блокировку пользователя?',
                });
                break;
            case 'clear_chat':
                setConfirmContent({
                    title: 'Вы действительно хотите очистить чат с пользователем?',
                    description: 'Все старые сообщения пропадут, вы подтверждаете очистку чата?',
                });
                break;
            case 'complain':
                setConfirmContent({
                    title: 'Пожаловаться на пользователя?',
                    description:
                        'Подтвердите, что хотите пожаловаться на этого пользователя. Ваше обращение будет рассмотрено.',
                });
                break;
            default:
                setConfirmContent(initialModalConfirmContentState);
                break;
        }
    };

    const handleEventSettings = (settings: SettingsData['action']) => {
        try {
            setModalConfirmContent(settings);
            setSettingsData(settings);
            setIsOpenModal(false);
            setTimeout(() => setIsOpenConfirm(true), 300);
        } catch (error) {
            console.error(error);
        }
    };

    const clearChatWithUserAction = async (profileId: number | '') => {
        await clearChatById(profileId);
        refetchMessages?.();
    };

    const handleConfirm = async () => {
        try {
            switch (settingsData) {
                case 'block':
                    await blockedProfileById(profileId);
                    navigate(routesApp.messages);
                    break;

                case 'complain':
                    await complainProfileById(profileId);
                    navigate(routesApp.messages);

                    break;

                case 'clear_chat':
                    await clearChatWithUserAction(profileId);
                    break;
                default:
                    break;
            }
            setIsOpenConfirm(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <HeaderMenu
                className={styles.chatHeaderMenu}
                backLink={routesApp.messages}
                headerBg="white"
                isfixed
                append={
                    <Button
                        className={styles.menuDropdown}
                        onClick={() => setIsOpenModal(true)}
                    >
                        <VerticalDotsIcon
                            width={24}
                            height={24}
                        />
                    </Button>
                }
            >
                <div className={styles.headerChat}>
                    {isLoading ? (
                        <SekeletonUserInfoChat />
                    ) : (
                        <UserInfoChat
                            photo={profileChat?.photos[0]?.url}
                            name={profileChat?.public_name}
                            ageDate={profileChat?.dob}
                            userId={profileChat?.id}
                        />
                    )}
                </div>
            </HeaderMenu>
            <ModalSettingsUserChat
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                handleChangeSettingsProfile={handleEventSettings}
                username={profileChat?.public_name ?? ''}
            />
            <Confirm
                isOpen={isOpenConfirm}
                onClose={() => setIsOpenConfirm(false)}
                title={confirmContent.title}
                text={confirmContent.description}
                onConfirm={handleConfirm}
                buttonTextSubmit="Принять"
                isLoading={isLoadingBlocked || isLoadingClearChat || isLoadingComplain}
            />
        </>
    );
};
