import React from 'react';
import styles from './match-found.module.scss';
import { Profile } from '@/types/profile';
import { GalleryImages } from '@/components/gallery-images';
import { Button, Typography } from '@/ui-kit';
import { routesApp } from '@/constans/routes';
import { generatePath } from 'react-router-dom';
import clsx from 'clsx';
import { CloseIconLight } from '@/assets/icons';

interface Props {
    user?: Profile;
    clearProfileId: () => void;
}

export const MatchFound: React.FC<Props> = ({ user, clearProfileId }) => {
    return (
        <div className={clsx(styles.wrapper, user && styles.visibleWrapper)}>
            <div className={styles.content}>
                <div
                    className={clsx(styles.closeIcon)}
                    onClick={() => clearProfileId()}
                >
                    <CloseIconLight />
                </div>
                <div className={styles.galleryWrapper}>
                    <GalleryImages
                        photos={user?.photos ?? []}
                        isMatched={true}
                    />
                </div>
                <div className={styles.cardFooter}>
                    <Typography
                        fontWeight="bold"
                        typeColor="white"
                        className={styles.cardFooter__title}
                        fontFamily="SFProText"
                        variant="h4"
                        center
                    >
                        Новая пара!
                    </Typography>

                    <Typography
                        typeColor="white"
                        fontFamily="SFProText"
                        center
                        className={styles.cardFooter__text}
                        fontWeight="normal"
                    >
                        {user?.public_name} и ты нравитесь друг другу.
                    </Typography>
                    <Typography
                        typeColor="white"
                        fontFamily="SFProText"
                        center
                        className={styles.cardFooter__text}
                        fontWeight="normal"
                    >
                        Напиши {user?.gender === 'female' ? 'ей' : 'ему'} первый
                    </Typography>

                    <Button
                        isLink
                        to={generatePath(routesApp.chat, { id: String(user?.id) })}
                        typeColor="orange"
                        textColor="white"
                    >
                        Перейти к переписке
                    </Button>
                </div>
            </div>
        </div>
    );
};
