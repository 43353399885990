import { ArrowTopFillIcon, FilterIcon } from '@/assets/icons';
import { Button, Typography } from '@/ui-kit';
import styles from './card-swipe-user-new.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import { ButtonsLikeAndDislike } from '../buttons-like-and-dislike';
import { GalleryImages } from '../gallery-images';
import { Profile } from '@/types/profile';
import { useVoteProfileById } from '@/hooks/use-vote-profile-by-id';
import clsx from 'clsx';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { SuccessfulRegistration } from '../successful-registration';
import { useSuccessfulMessage } from '@/store/useSuccessfulMessage';
import { LastCardQuestionnaire } from '../last-card-questionnaire';
import { CardUserInfo } from './components/card-user-info';
import { ModalProfileFilter } from '../modal-profile-filter';
import { MatchFound } from '@/components/match-found';
import { useGetProfileById } from '@/hooks/use-get-profile-by-id';

// swiper modules
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import EffectTinder from '@/libs/swiper/effect-tinder.esm';
import 'swiper/scss';

interface Props {
    users?: Profile[];
    refetchData?: () => void;
}

type Dir = 'left' | 'right' | null;

export const CardSwiperUserNew: React.FC<Props> = ({ users = [], refetchData }) => {
    const { isOpen, setIsOpen } = useSuccessfulMessage((state) => state);
    const [profileId, setProfileId] = useState<number | null>(null);
    const { data: profile, isError, isLoading } = useGetProfileById(profileId);
    const { mutateAsync: voteProfileById } = useVoteProfileById();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisibleDetails, setIsVisibleDetails] = useState(false);
    const [isFullyViewed, setIsFullyViewed] = useState(false);
    const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
    const detailsRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const currentSwipeSlide = users[currentIndex];
    // swiper vars and init
    const swiperRef = useRef<any>(null);

    const canSwipe = currentIndex >= 0;
    const isVisibleButtons = !isVisibleDetails && !isFullyViewed && users.length > 0;

    const incrementCurrentIndex = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const handelClickButton = async (dir: Dir) => {
        if (swiperRef.current) {
            if (dir === 'left') {
                swiperRef.current.swiper.tinder.no();
            }
            if (dir === 'right') {
                swiperRef.current.swiper.tinder.yes();
            }
        }

        if (isVisibleDetails) {
            setIsVisibleDetails(() => {
                const nextState = false;

                if (!nextState) {
                    const topBlock = document.querySelector(`.${styles.tinderCardWrapper}`);
                    if (topBlock) {
                        topBlock.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                }

                return nextState;
            });
        }
    };

    const clearProfileId = () => {
        setProfileId(null);
    };

    // Сброс состояния при изменении списка пользователей
    useEffect(() => {
        setCurrentIndex(0);
        if (users.length > 0) {
            setIsFullyViewed(false);
            // setCurrentSwipeSlide(users[0]);
        } else {
            setIsVisibleDetails(false);
        }
    }, [users]);

    const toggleVisibleDetails = () => {
        setIsVisibleDetails((prev) => {
            const nextState = !prev;

            if (!nextState) {
                const topBlock = document.querySelector(`.${styles.tinderCardWrapper}`);
                if (topBlock) {
                    topBlock.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }

            return nextState;
        });
    };

    const sendVoteRequest = async (dir: 'left' | 'right', index: number) => {
        try {
            const vote = dir === 'left' ? 'dislike' : 'like';
            const response = await voteProfileById({
                profileId: users[index].id,
                voteProfileDto: {
                    vote,
                },
            });

            const { data } = response;

            if (data.match) {
                setProfileId(data.id);
            }
            incrementCurrentIndex();
        } catch (e) {
            console.error('Error sending vote:', e);
        } finally {
            setIsVisibleDetails(false);
        }
    };

    const onSwipe = async (swiper: any, direction: 'left' | 'right') => {
        const index = swiper.activeIndex - 1;
        await sendVoteRequest(direction, index);
        if (index === users.length - 1) {
            refetchData?.();
        }
    };

    const isPageAtTop = (): boolean => {
        if (!wrapperRef.current) return false;

        const scrollTop = wrapperRef.current.scrollTop;
        return scrollTop === 0;
    };

    const bindSwipe = useDrag(
        ({ down, movement: [_, my] }) => {
            if (!down && my > 5 && isVisibleDetails && isPageAtTop()) {
                setIsVisibleDetails(false);
            }

            if (!down && my < 5 && !isVisibleDetails && isPageAtTop()) {
                setIsVisibleDetails(true);
            }
        },
        { axis: 'y' },
    );

    // useEffect(() => {
    //     const currentWrapper = wrapperRef.current;
    //
    //     const handleScroll = () => {
    //         if (isPageAtTop() && isVisibleDetails) {
    //             setIsVisibleDetails(false);
    //         }
    //     };
    //
    //     if (currentWrapper) {
    //         currentWrapper.addEventListener('scroll', handleScroll);
    //     }
    //
    //     return () => {
    //         if (currentWrapper) {
    //             currentWrapper.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, [isVisibleDetails]);

    const renderCards = (
        <div
            className="swiper"
            style={{ zIndex: 3 }}
        >
            <Swiper
                modules={[EffectTinder]}
                grabCursor={true}
                effect="tinder"
                className={styles.tinderCardWrapper}
                ref={swiperRef}
                onTinderSwipe={onSwipe}
                allowTouchMove={true}
            >
                {users.map((user, index) => (
                    <SwiperSlide
                        key={user.id}
                        className="swiper-slide"
                    >
                        <div className="gradientNo" />
                        <div className="gradientYes" />
                        <div className={styles.wrapper}>
                            <div className={clsx(styles.card, currentIndex === index && styles.active)}>
                                <GalleryImages
                                    photos={user.photos}
                                    isCurrentCard={currentIndex === index}
                                />
                                <div className={clsx(styles.cardInfo, isVisibleDetails && styles.visibleDetails)}>
                                    <Typography
                                        typeColor="white"
                                        fontWeight="medium"
                                        variant="h3"
                                        className={styles.cardInfoText}
                                    >
                                        {user.public_name}
                                        {differenceInYearsResult(user?.dob) > 0
                                            ? ', ' + differenceInYearsResult(user?.dob)
                                            : null}
                                    </Typography>
                                    <Typography
                                        typeColor="white"
                                        fontWeight="normal"
                                        variant="p"
                                        className={styles.descriptionIntro}
                                    >
                                        {user.about_me}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <SwiperSlide className="swiper-slide">
                    <div className={clsx(styles.loadMoreCard)} />
                </SwiperSlide>
            </Swiper>
        </div>
    );

    return (
        <div
            className={clsx(styles.mainWrapper, isVisibleDetails && styles.visibleDetails)}
            ref={wrapperRef}
            {...bindSwipe()}
        >
            <div className={clsx(styles.container, isVisibleDetails && styles.visibleDetails)}>
                {!isFullyViewed && canSwipe && (
                    <div
                        className={clsx(styles.arrowTop, isVisibleDetails && styles.visibleDetails)}
                        onClick={(event) => toggleVisibleDetails()}
                    >
                        <ArrowTopFillIcon />
                    </div>
                )}
                <Button
                    onClick={() => setIsModalFilterOpen(true)}
                    className={styles.filterButton}
                >
                    <FilterIcon />
                </Button>
                {renderCards}

                {users.length > 0 && <div className={clsx(styles.loadMoreCard)} />}
                <LastCardQuestionnaire onClickOpenFilter={() => setIsModalFilterOpen(true)} />

                <ButtonsLikeAndDislike
                    onSwipeLeft={() => handelClickButton('left')}
                    onSwipeRight={() => handelClickButton('right')}
                    visible={isVisibleButtons}
                />

                {isOpen && <SuccessfulRegistration onClose={() => setIsOpen(false)} />}
                <ModalProfileFilter
                    isOpen={isModalFilterOpen}
                    onClose={() => setIsModalFilterOpen(false)}
                />
            </div>
            <CardUserInfo
                currentSwipeSlide={currentSwipeSlide}
                isVisibleDetails={isVisibleDetails}
                detailsRef={detailsRef}
                onSwipeLeft={handelClickButton}
                onSwipeRight={handelClickButton}
            />

            {profile?.data && profileId && (
                <MatchFound
                    user={profile?.data}
                    clearProfileId={clearProfileId}
                />
            )}
        </div>
    );
};
