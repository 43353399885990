import { Profile } from '@/types/profile';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface CurrentChatStore {
    currentChat: Profile | null;
    setCurrentChat: (currentChat: Profile) => void;
}

export const useCurrentChatInfo = create<CurrentChatStore>()(
    devtools(
        immer((set) => ({
            currentChat: null,
            setCurrentChat: (currentChat) => set({ currentChat }),
        })),
    ),
);
