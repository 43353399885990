import React, { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';
import styles from './button.module.scss';
import { PulseLoader } from 'react-spinners';

type ButtonVariant = 'small' | 'stretched';
type ButtonColor = 'white' | 'orange';
type ButtonTextColor = 'white' | 'black' | 'orange' | 'gray';

interface CommonProps {
    variant?: ButtonVariant;
    typeColor?: ButtonColor;
    textColor?: ButtonTextColor;
    className?: string;
    link?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    isLink?: boolean;
}

type ButtonProps = CommonProps & ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = CommonProps & AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps;

type Props = ButtonProps | AnchorProps;

export const Button: React.FC<Props> = ({
    variant = 'small',
    typeColor = 'white',
    link,
    className,
    children,
    isLoading,
    isDisabled,
    textColor,
    isLink,
    ...rest
}) => {
    const classes = clsx(
        styles.button,
        styles[variant],
        styles[typeColor],
        link && styles.link,
        className,
        isLoading && styles.isLoading,
        isDisabled && styles.isDisabled,
        textColor && styles[`text-${textColor}`],
    );

    if ('to' in rest && isLink) {
        const { to, replace, ...linkProps } = rest;

        return (
            <Link
                to={to}
                replace={replace}
                className={classes}
                {...linkProps}
            >
                {children}
            </Link>
        );
    }

    const buttonProps = rest as ButtonProps;

    return (
        <button
            className={classes}
            disabled={isLoading}
            {...buttonProps}
        >
            {isLoading ? (
                <PulseLoader
                    size={12}
                    color="currentColor"
                />
            ) : (
                children
            )}
        </button>
    );
};
