import React from 'react';
import styles from './premium-item.module.scss';
import clsx from 'clsx';
import { CheckEmptyIcon, CheckIcon, StarPremiumIcon } from '@/assets/icons';
import { Typography } from '@/ui-kit';

interface Props {
    isActive?: boolean;
    date: string;
    price: string | number;
    saving?: string;
    isPopular?: boolean;
    onClick?: () => void;
}

export const PremiumItem: React.FC<Props> = ({ isActive, date, price, saving, isPopular, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={clsx(styles.premiumItem, isActive && styles.active)}
        >
            <div className={styles.dateBlock}>
                {isActive ? (
                    <CheckIcon
                        width={20}
                        height={20}
                    />
                ) : (
                    <CheckEmptyIcon
                        width={20}
                        height={20}
                    />
                )}
                <Typography
                    fontWeight="semibold"
                    fontFamily="SFProText"
                    typeColor={isActive ? 'white' : 'black'}
                >
                    {date}
                </Typography>
                {isPopular && (
                    <Typography
                        uppercase
                        className={styles.badge}
                        typeColor="white"
                        fontFamily="SFProText"
                    >
                        Популярный
                    </Typography>
                )}
            </div>
            <div className={styles.priceBlock}>
                <Typography
                    fontFamily="SFProText"
                    className={styles.priceText}
                    typeColor={isActive ? 'white' : 'black'}
                >
                    {price}
                </Typography>
                <StarPremiumIcon
                    width={16}
                    height={16}
                />
            </div>
            {saving && <div className={styles.savingLabel}>{saving}</div>}
        </button>
    );
};
