import React from 'react';
import { getTrackBackground } from 'react-range';
import { IRenderTrackParams } from 'react-range/lib/types';

type Props = {
    params: IRenderTrackParams;
    values: number[];
    min: number;
    max: number;
};

const CustomTrack: React.FC<Props> = ({ params, values, max, min }) => {
    const { children, props } = params;

    return (
        <div
            {...props}
            style={{
                ...props.style,
                height: '7px',
                borderRadius: '7px',
                width: '100%',
                background: getTrackBackground({
                    colors: ['#D9D9D9', '#F26A1C', '#D9D9D9'],
                    max,
                    min,
                    values,
                }),
            }}
        >
            {children}
        </div>
    );
};

export default CustomTrack;
