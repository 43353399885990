import React from 'react';
import styles from './empty-couples.module.scss';
import { Button, Typography } from '@/ui-kit';
import { MessageIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';

interface Props {}

export const EmptyCouples: React.FC<Props> = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>
                <MessageIcon
                    width={28}
                    height={28}
                />
            </div>
            <Typography
                fontWeight="semibold"
                center
                fontFamily="SFProText"
                className={styles.title}
            >
                У вас пока нет пар
            </Typography>
            <Typography
                center
                fontFamily="SFProText"
                className={styles.text}
            >
                Вы&nbsp;можете найти новые знакомства в&nbsp;ленте и&nbsp;начать общение
            </Typography>
            <Button
                className={styles.button}
                typeColor="orange"
                isLink
                to={routesApp.browse}
            >
                Найти пару
            </Button>
        </div>
    );
};
