import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetLikesCounter = () => {
    return useQuery({
        queryKey: ['like_me_count'],
        queryFn: () => profilesApi.getLikesCounter(),
    });
};
