import { toast } from 'react-toastify';

export const showToast = (type: 'error' | 'success' | 'warning' | 'info', message: string) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 3000,
            });
            break;
        case 'error':
            toast.error(message, {
                position: 'bottom-center',
                autoClose: 3000,
            });
            break;
        case 'warning':
            toast.warning(message, {
                position: 'bottom-center',
                autoClose: 3000,
            });
            break;
        case 'info':
            toast.info(message, {
                position: 'bottom-center',
                autoClose: 3000,
            });
            break;
        default:
            toast(message, {
                position: 'bottom-center',
                autoClose: 3000,
            });
    }
};
