import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { routesApp } from '@/constans/routes';

import { AppLayout } from '@/components/layouts/app-layout';
import { Home } from '@/pages/home';
import { Registration } from '@/pages/registration';
import { Browse } from '@/pages/browse';
import { Messages } from '@/pages/messages';
import { Liked } from '@/pages/liked';
import { Profile } from '@/pages/profile';
import { Subscription } from '@/pages/subscription';
import { ProfileEdit } from '@/pages/profile-edit';
import { Settings } from '@/pages/settings';
import { Chat } from '@/pages/chat';
import { RegistrationLayout } from '@/components/layouts/registration-layout';
import { PrivateRoutes } from '@/components/private-routes';
import { AuthProvider } from '@/components/auth-provider';
import { TgProvider } from '@/components/tg-provider';
import { ProfileView } from '@/pages/profile-view';
import TermsOfService from '@/pages/terms-of-service/terms-of-service';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<TgProvider />}>
            {/* <Route element={<PublicRoutes browsePath={routesApp.browse} />}> */}

            <Route
                path={routesApp.home}
                element={<Home />}
            />
            <Route element={<RegistrationLayout />}>
                <Route
                    path={routesApp.registration}
                    element={<Registration />}
                />
            </Route>
            {/* </Route> */}
            <Route element={<PrivateRoutes loginPath={routesApp.registration} />}>
                <Route element={<AppLayout />}>
                    <Route
                        path={routesApp.browse}
                        element={<Browse key={routesApp.browse} />}
                    />
                    <Route
                        path={routesApp.messages}
                        element={<Messages key={routesApp.messages} />}
                    />
                    <Route
                        path={routesApp.liked}
                        element={<Liked key={routesApp.liked} />}
                    />
                    <Route
                        path={routesApp.profile}
                        element={<Profile key={routesApp.profile} />}
                    />
                    <Route
                        path={routesApp.subscription}
                        element={<Subscription key={routesApp.subscription} />}
                    />
                    <Route
                        path={routesApp.profileEdit}
                        element={<ProfileEdit key={routesApp.profileEdit} />}
                    />
                    <Route
                        path={routesApp.settings}
                        element={<Settings key={routesApp.settings} />}
                    />
                    <Route
                        path={routesApp.profileView}
                        element={<ProfileView />}
                    />
                    <Route
                        path={routesApp.termsOfService}
                        element={<TermsOfService />}
                    />
                </Route>
                <Route
                    path={routesApp.chat}
                    element={<Chat />}
                />
            </Route>
        </Route>,
    ),
    {
        basename: '/app',
    },
);

const Router: React.FC = () => (
    <AuthProvider>
        <RouterProvider router={router} />
    </AuthProvider>
);

export default Router;
