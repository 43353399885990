import { BlockedIcon, DeleteIcon, FlagIcon } from '@/assets/icons';

export interface SettingsData {
    title: string;
    type: string;
    icon: React.FC;
    action: 'block' | 'complain' | 'clear_chat';
}

export const settingsData: SettingsData[] = [
    {
        title: 'Очистить чат с ',
        type: 'Очистить чат с пользователем.',
        icon: BlockedIcon,
        action: 'clear_chat',
    },
    {
        title: 'ЗАБЛОКИРОВАТЬ ПОЛЬЗОВАТЕЛЯ ',
        type: 'Вы больше не будете видеть друг друга.',
        icon: DeleteIcon,
        action: 'block',
    },
    {
        title: 'ПОЖАЛОВАТЬСЯ НА ',
        type: 'Не волнуйся: пользователь об этом не узнает.',
        icon: FlagIcon,
        action: 'complain',
    },
];
