import React from 'react';
import styles from './empty-messages.module.scss';
import { MessageIcon } from '@/assets/icons';
import { Typography } from '@/ui-kit';

interface Props {
    title: string;
    text: string;
}

export const EmptyMessages: React.FC<Props> = ({ title, text }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>
                <MessageIcon
                    width={28}
                    height={28}
                />
            </div>
            <Typography
                fontWeight="semibold"
                center
                fontFamily="SFProText"
            >
                {title}
            </Typography>
            <Typography
                center
                fontFamily="SFProText"
            >
                {text}
            </Typography>
        </div>
    );
};
