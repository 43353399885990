import { profilesApi } from '@/api';
import { showToast } from '@/utils/show-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useBlockedProfileById = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: profilesApi.blockProfileById,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profiles'] });
            showToast('success', 'Профиль успешно заблокирован');
        },
        onError: (error) => {
            console.error('Error blocked profile:', error);
            showToast('error', 'Ошибка блокировки профиля');
        },
    });

    return mutation;
};
