import React from 'react';
import { Button, Modal } from '@/ui-kit';
import styles from './modal-profile-filter.module.scss';

import { useAuth } from '@/store/useAuth';
import { FilterProfile } from './components/filter-profile';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
}

export const ModalProfileFilter: React.FC<Props> = ({ isOpen, onClose }) => {
    const userData = useAuth((state) => state.getUser());

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isDeskorTop={false}
            title="Фильтр"
        >
            {userData && (
                <FilterProfile
                    onCloseModal={onClose}
                    userData={userData}
                />
            )}
        </Modal>
    );
};
