import { HeaderMenu } from '@/components/header-menu';
import styles from './profile.module.scss';
import { SettingsIcon } from '@/assets/icons';
import { Button } from '@/ui-kit';
import { routesApp } from '@/constans/routes';
import { SettingsProfile } from '@/components/settings-profile';

export const Profile: React.FC = () => {
    return (
        <div className={styles.profile}>
            <HeaderMenu
                title="Профиль"
                marginBottom={20}
                append={
                    <Button
                        isLink
                        to={routesApp.settings}
                        className={styles.button}
                    >
                        <SettingsIcon />
                    </Button>
                }
            />
            <SettingsProfile />
        </div>
    );
};
