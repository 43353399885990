import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss';
import * as Sentry from '@sentry/react';
import { App } from './app';

Sentry.init({
    dsn: 'https://d534407925e70b4569c4358917020691@rocketsentry.ru/97',
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
);
