import { useMemo, useState } from 'react';
import styles from './gallery-images.module.scss';
import { useSwipedChange } from '@/store/useSwipedChange';
import { Photo } from '@/types/profile';
import clsx from 'clsx';
import { UserAvatarIcon } from '@/assets/icons';
import { Pagination } from './componenets/pagination';
import { Navigation } from './componenets/navigation';

interface Props {
    photos: Photo[];
    isVisibleDetails?: boolean;
    isMatched?: boolean;
    isCurrentCard?: boolean;
}

export const GalleryImages: React.FC<Props> = ({ photos, isVisibleDetails, isMatched, isCurrentCard }) => {
    const { isSwiping } = useSwipedChange((state) => state);
    const [currentIndexGallery, setCurrentIndexGallery] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const currentGalleryImage = useMemo(() => photos[currentIndexGallery], [currentIndexGallery, photos]);
    const dir = useSwipedChange((state) => state.dir);

    const handleChangeGallery = (idx: number, dir: 'left' | 'right') => {
        if (isSwiping) {
            return;
        }

        if (dir === 'left' && idx > 0) {
            setCurrentIndexGallery(idx - 1);
        }

        if (dir === 'right' && idx < photos.length - 1) {
            setCurrentIndexGallery(idx + 1);
        }
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className={clsx(styles.wrapper, { [styles.visibleDetails]: isVisibleDetails })}>
            <Pagination
                total={photos.length}
                activeIndex={currentIndexGallery}
                isVisible={photos.length > 1}
            />
            <div
                className={clsx(
                    styles.image,
                    isMatched && styles.matchedImage,
                    !currentGalleryImage?.url && styles.imageEmpty,
                    isCurrentCard &&
                        (dir === 'right' ? styles.gradientRight : dir === 'left' ? styles.gradientLeft : null),
                )}
            >
                {isLoading && <div className={styles.preloader} />}
                {currentGalleryImage?.url ? (
                    <img
                        src={currentGalleryImage?.url}
                        alt={currentGalleryImage?.metadata.filename}
                        onLoad={handleImageLoad}
                        onError={() => setIsLoading(false)}
                    />
                ) : (
                    <UserAvatarIcon
                        className={styles.avatarIcon}
                        width={150}
                        height={150}
                    />
                )}
            </div>
            <Navigation
                currentIndex={currentIndexGallery}
                onChange={handleChangeGallery}
            />
        </div>
    );
};
