import React from 'react';
import { Button, Typography } from '@/ui-kit';
import styles from './successful-registration.module.scss';
import { Hand, StartIcon } from '@/assets/icons';
interface Props {
    onClose?: () => void;
}
export const SuccessfulRegistration: React.FC<Props> = ({ onClose }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.illustration}>
                    <StartIcon className={styles['star-1']} />
                    <StartIcon className={styles['star-2']} />
                    <StartIcon className={styles['star-3']} />
                    <Hand className={styles.hand} />
                </div>
                <Typography
                    variant="h2"
                    fontWeight="medium"
                    typeColor="white"
                    center
                    className={styles.title}
                >
                    Ты зарегистрирован!
                </Typography>
                <Typography
                    variant="p"
                    fontWeight="normal"
                    typeColor="white"
                    center
                    className={styles.text}
                >
                    Ставьте лайк понравившимся
                    <br />
                    людям, при&nbsp;взаимном лайке вы&nbsp;сможете
                    <br />
                    написать&nbsp;друг&nbsp;другу!
                </Typography>
                <Button
                    typeColor="orange"
                    link
                    className={styles.button}
                    onClick={onClose}
                >
                    Начать
                </Button>
            </div>
        </div>
    );
};
