import React from 'react';
import styles from './terms-of-service.module.scss';
import { HeaderMenu } from '@/components/header-menu';
import { routesApp } from '@/constans/routes';
import { Typography } from '@/ui-kit';

const TermsOfService = () => {
    return (
        <>
            <HeaderMenu
                className={styles.headerMenu}
                backLink={routesApp.messages}
                headerBg="white"
                isfixed
            >
                <Typography
                    variant="p"
                    fontWeight="medium"
                    className={styles.header__title}
                    center
                >
                    О сервисе
                </Typography>
            </HeaderMenu>

            <div className={styles.content}>
                <Typography
                    variant="h6"
                    fontWeight="medium"
                    className={styles.content__title}
                    center
                >
                    О приложении
                </Typography>

                <Typography
                    variant="p"
                    className={styles.content__text}
                >
                    Описание приложения
                </Typography>

                <Typography
                    variant="h6"
                    className={styles.content__title}
                    center
                >
                    Правила пользования сервисом
                </Typography>

                <Typography
                    variant="p"
                    className={styles.content__text}
                >
                    Пункты правил
                </Typography>
            </div>
        </>
    );
};

export default TermsOfService;
