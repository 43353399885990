import React, { useCallback, useMemo, useState } from 'react';
import styles from './filter-profile.module.scss';
import { Button, Radio, RangeSlider, Select, Typography } from '@/ui-kit';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetRegions } from '@/hooks/use-get-regions';
import { filterProfileSchema, FilterProfileSchemaType } from '@/schemes/filter-profile-schema';
import { Region, SearchParamsRegions } from '@/api/region/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Profile } from '@/types/profile';
import { debounce } from 'lodash';
import { useUpdateUserData } from '@/hooks/use-update-profile';
import { useQueryClient } from '@tanstack/react-query';
import { renderCity } from '@/utils/render-city';

interface Props {
    userData: Profile;
    onCloseModal?: () => void;
}

const DELAY_SEND_DATA = 500;

export const FilterProfile: React.FC<Props> = ({ userData, onCloseModal }) => {
    const { mutateAsync: updateUserData, isPending } = useUpdateUserData();
    const queryClient = useQueryClient();
    const [seatchParamsCities, setSeatchParamsCities] = useState<SearchParamsRegions>({ q: '' });
    const { isLoading: isLoadingCities, data: dataRegions } = useGetRegions(seatchParamsCities);

    const defaultValues = useMemo(() => {
        const getMinAge = () => {
            return userData.min_age !== null ? (userData.min_age >= 60 ? 60 : userData.min_age) : 18;
        };

        const getMaxAge = () => {
            return userData.max_age !== null ? (userData.max_age >= 60 ? 60 : userData.max_age) : 24;
        };
        return {
            looks_for: userData?.looks_for ?? 'any',
            region: userData?.region ? { label: renderCity(userData.region), value: userData.region.id } : undefined,
            min_age: getMinAge(),
            max_age: getMaxAge(),
        };
    }, [userData]);

    const [minAge, setMinAge] = useState(defaultValues.min_age);
    const [maxAge, setMaxAge] = useState(defaultValues.max_age);

    const methods = useForm<FilterProfileSchemaType>({
        resolver: zodResolver(filterProfileSchema),
        defaultValues,
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = methods;
    const handleChangeRangeAge = (value: any) => {
        methods.setValue('min_age', value[0]);
        methods.setValue('max_age', value[1]);
        setMinAge(value[0]);
        setMaxAge(value[1]);
    };

    const handleSearchCityDebounce = useCallback(
        debounce((q: string) => {
            setSeatchParamsCities({ q });
        }, DELAY_SEND_DATA),
        [seatchParamsCities],
    );

    const onSubmit = async (data: FilterProfileSchemaType) => {
        const minAgeToSend = data.min_age >= 60 ? 100 : data.min_age;
        const maxAgeToSend = data.max_age >= 60 ? 100 : data.max_age;
        await updateUserData({
            looks_for: data.looks_for,
            min_age: minAgeToSend,
            max_age: maxAgeToSend,
            region_id: data.region?.value,
        });
        queryClient.invalidateQueries({ queryKey: ['profiles'] });
        onCloseModal?.();
    };
    return (
        <div className={styles.wrapper}>
            <Button
                className={styles.buttonReset}
                onClick={() => {
                    methods.reset();
                    setMinAge(defaultValues.min_age);
                    setMaxAge(defaultValues.max_age);
                }}
            >
                Очистить
            </Button>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formRadioGroup}>
                        <Radio
                            label="Мужчина"
                            value="male"
                            {...register('looks_for')}
                            isTypeButton
                        />
                        <Radio
                            label="Женщина"
                            value="female"
                            {...register('looks_for')}
                            isTypeButton
                        />
                        <Radio
                            label="Не важно"
                            value="any"
                            {...register('looks_for')}
                            isTypeButton
                        />
                    </div>
                    <Typography
                        typeColor="gray"
                        fontFamily="SFProText"
                        className={styles.label}
                    >
                        Возрастной диапазон
                    </Typography>
                    <RangeSlider
                        maxStart={maxAge}
                        minStart={minAge}
                        onChange={handleChangeRangeAge}
                    />
                    <Typography
                        typeColor="gray"
                        fontFamily="SFProText"
                        className={styles.label}
                    >
                        Ваш город
                    </Typography>
                    <div className={styles.selectWrap}>
                        <Select
                            name="region"
                            isLoading={isLoadingCities}
                            options={dataRegions?.data?.map((region: Region) => ({
                                label: renderCity(region),
                                value: region.id,
                            }))}
                            onSearch={handleSearchCityDebounce}
                        />
                    </div>
                    <Button
                        typeColor="orange"
                        variant="stretched"
                        isLoading={isPending}
                    >
                        Применить
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};
