import { z } from 'zod';

export const profileEditSchema = z.object({
    about_me: z
        .string()
        .min(0, 'Описания обязательно')
        .max(300, 'Максимальная длина описания - 300 символов')
        .nullable(),
    region: z.object({ label: z.string(), value: z.number() }).nullable(),
    purpose: z.enum(['love', 'fun', 'not_specified']).nullable(),
    dob: z.string().min(1, { message: 'Дата обязательна' }),
    travel_plans: z.string().min(0, 'Поле не должно быть пустым').max(500, 'Описание слишком длинное').nullable(),
});

export type ProfileEditSchemaType = z.infer<typeof profileEditSchema>;
