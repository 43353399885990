import { z } from 'zod';

export const filterProfileSchema = z.object({
    min_age: z.number(),
    max_age: z.number(),
    looks_for: z.enum(['male', 'female', 'any'], { message: 'Пол обязателен' }),
    region: z.object({ label: z.string(), value: z.number() }).nullable(),
});

export type FilterProfileSchemaType = z.infer<typeof filterProfileSchema>;
