import { InfoIcon, UserSettingsIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';

interface RouteSettings {
    title: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    path: string;
    value: string;
}

export const dataSettingsRoutes: RouteSettings[] = [
    {
        title: 'Личные данные',
        icon: UserSettingsIcon,
        path: routesApp.profileEdit,
        value: 'Личные данные',
    },
    {
        title: 'О сервисе',
        icon: InfoIcon,
        path: routesApp.termsOfService,
        value: 'О сервисе',
    },
];
