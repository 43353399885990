import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetProfileById = (profileId: number | string | null) => {
    return useQuery({
        queryKey: ['profiles', profileId],
        // queryFn: () => profilesApi.getProfileById(profileId),
        queryFn: () => (profileId ? profilesApi.getProfileById(profileId) : Promise.resolve(null)),
    });
};
