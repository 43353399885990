import React, { ChangeEvent, forwardRef } from 'react';
import styles from './radio.module.scss';
import clsx from 'clsx';

interface Props {
    label?: string;
    name: string;
    value: string;
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    isTypeButton?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
    ({ label, name, value, checked, defaultChecked, onChange, isTypeButton }, ref) => {
        return (
            <label className={clsx(styles.container, isTypeButton && styles.button)}>
                <input
                    ref={ref}
                    type="radio"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    defaultChecked={defaultChecked}
                />
                <span className={styles.radio}></span>
                {label && <span className={clsx(styles.label)}>{label}</span>}
            </label>
        );
    },
);

Radio.displayName = 'Radio';
