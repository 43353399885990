import { DataPicker, Input, Radio, Select, Textarea, Typography, NativeDatePicker } from '@/ui-kit/';
import { FieldBlock } from '@/components/field-block';
import styles from './main-info-registartion-form.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { registrationStepFirstSchema, RegistrationStepFirstType } from '@/schemes/registration-step-first-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { type Profile } from '@/types/profile';
import { useGetRegions } from '@/hooks/use-get-regions';
import { Region, SearchParamsRegions } from '@/api/region/types';
import { ButtonSubmitStep } from '../button-submit-step';
import { renderCity } from '@/utils/render-city';

const DELAY_SEND_DATA = 1000;

interface Props {
    userData?: Profile | null;
    onUpdateUserData: (data: RegistrationStepFirstType) => Promise<any>;
    nextStep: () => void;
}

export const MainInfoRegistartionForm: React.FC<Props> = ({ userData, onUpdateUserData, nextStep }) => {
    const initialValues = useMemo(() => {
        return {
            public_name: userData?.public_name ?? '',
            about_me: userData?.about_me ?? null,
            region: userData?.region ? { label: renderCity(userData.region), value: userData.region.id } : undefined,
            gender: userData?.gender ?? undefined,
            dob: userData?.dob ?? '',
            travel_plans: userData?.travel_plans ?? null,
        };
    }, [userData]);

    const [seatchParamsCities, setSeatchParamsCities] = useState<SearchParamsRegions>({ q: '' });

    const { isLoading: isLoadingCities, data: dataRegions } = useGetRegions(seatchParamsCities);

    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<RegistrationStepFirstType>({
        resolver: zodResolver(registrationStepFirstSchema),
        defaultValues: initialValues,
    });
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = methods;

    const onSubmit = async (data: RegistrationStepFirstType) => {
        try {
            setIsLoading(true);
            if (isDirty) {
                await onUpdateUserData(data);
            }
            nextStep();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchCityDebounce = useCallback(
        debounce((q: string) => {
            setSeatchParamsCities({ q });
        }, DELAY_SEND_DATA),
        [seatchParamsCities],
    );

    const handleChangeUserDataDebounce = useCallback(
        debounce(async (data: any) => {
            console.log('handleChangeUserDataDebounce: ', data);
            return await onUpdateUserData(data);
        }, DELAY_SEND_DATA),
        [onUpdateUserData],
    );

    return (
        <FormProvider {...methods}>
            <form
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={styles.formContainer}>
                    <Typography
                        variant="p"
                        fontWeight="medium"
                        className={styles.titleForm}
                        center
                    >
                        Расскажите о себе
                    </Typography>
                    <FieldBlock error={!!errors?.public_name?.message}>
                        <Input
                            placeholder="Ваше имя"
                            label="Имя"
                            required
                            {...register('public_name', {
                                onChange(event) {
                                    handleChangeUserDataDebounce({
                                        public_name: event.target.value,
                                    });
                                },
                            })}
                            error={errors?.public_name?.message}
                        />
                    </FieldBlock>
                    <FieldBlock error={!!errors?.about_me?.message}>
                        <Textarea
                            label="О себе"
                            required
                            maxLength={300}
                            {...register('about_me', {
                                onChange(event) {
                                    handleChangeUserDataDebounce({
                                        about_me: event.target.value,
                                    });
                                },
                            })}
                            defaultValue={userData?.about_me}
                            placeholder="Работаю в IT, люблю серфить..."
                            error={errors?.about_me?.message}
                        />
                    </FieldBlock>
                    <FieldBlock error={!!errors?.region?.message}>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="medium"
                                className={styles.required}
                            >
                                Регион поиска
                            </Typography>
                        </div>
                        <Select
                            name="region"
                            isLoading={isLoadingCities}
                            options={dataRegions?.data?.map((region: Region) => ({
                                label: renderCity(region),
                                value: region.id,
                            }))}
                            onSearch={handleSearchCityDebounce}
                            onSelected={(cityData) => handleChangeUserDataDebounce({ region_id: cityData.value })}
                        />
                        {/* <FieldError message={errors?.city_id?.message} /> */}
                    </FieldBlock>

                    <FieldBlock error={!!errors?.gender?.message}>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="semibold"
                                className={styles.required}
                                isError={!!errors?.gender?.message}
                            >
                                Ваш пол
                            </Typography>
                        </div>
                        <div className={styles.formRadioGroup}>
                            <Radio
                                label="Мужчина"
                                value="male"
                                {...register('gender', {
                                    onChange(event) {
                                        handleChangeUserDataDebounce({
                                            gender: event.target.value,
                                        });
                                    },
                                })}
                            />
                            <Radio
                                {...register('gender', {
                                    onChange(event) {
                                        handleChangeUserDataDebounce({
                                            gender: event.target.value,
                                        });
                                    },
                                })}
                                label="Женщина"
                                value="female"
                            />
                        </div>
                    </FieldBlock>

                    {/*<DataPicker*/}
                    {/*    name="dob"*/}
                    {/*    placeholder="ДД / ММ / ГГГГ"*/}
                    {/*    isError={!!errors?.dob?.message}*/}
                    {/*/>*/}

                    <NativeDatePicker
                        name="dob"
                        placeholder="ДД / ММ / ГГГГ"
                        isError={!!errors?.dob?.message}
                    />

                    <FieldBlock>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="semibold"
                                className={styles.required}
                            >
                                Ваши тревел мечты
                            </Typography>
                        </div>
                        <Textarea
                            maxLength={500}
                            required
                            height={98}
                            placeholder="Собираюсь зимовать на Бали, ищу компанию..."
                            {...register('travel_plans', {
                                onChange(event) {
                                    handleChangeUserDataDebounce({ travel_plans: event.target.value });
                                },
                            })}
                            defaultValue={userData?.travel_plans || ''}
                            error={errors?.travel_plans?.message}
                        />
                    </FieldBlock>
                </div>
                <ButtonSubmitStep
                    isLoading={isLoading}
                    stepNumber={1}
                    isDisabled={!isValid}
                />
            </form>
        </FormProvider>
    );
};
