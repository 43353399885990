import { Button, Container } from '@/ui-kit';
import { AvatarProfile } from './components/avatar-profile';
import { useAuth } from '@/store/useAuth';
import styles from './settings-profile.module.scss';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { BadgePremium } from '../badge-premium';
import { routesApp } from '@/constans/routes';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const SettingsProfile: React.FC = () => {
    const userData = useAuth((state) => state.getUser());

    return (
        <Container className={styles.container}>
            <AvatarProfile
                name={userData?.public_name + ', ' + differenceInYearsResult(userData?.dob) ?? ''}
                avatar={userData?.photos?.[0]?.thumb_url?.toString()}
            />
            {/* <SettingsList /> */}
            {!userData?.is_paid ? (
                <BadgePremium
                    title="Узнай, кому ты нравишься"
                    description="Смотрите анкеты тех, кому вы понравились и отвечайте взаимностью"
                    type="column"
                >
                    <Button
                        className={styles.buttonBadge}
                        to={routesApp.subscription}
                        typeColor="orange"
                        isLink
                    >
                        Активировать Premium
                    </Button>
                </BadgePremium>
            ) : (
                <BadgePremium
                    title="Подписка Premium"
                    description={
                        'Активна до ' +
                        format(userData?.paid_till ? new Date(userData.paid_till) : new Date(), "d MMMM yyyy'г'", {
                            locale: ru,
                        })
                    }
                />
            )}
            {/* <Button
                typeColor="orange"
                variant="stretched"
                className={styles.settingsButton}
            >
                <LogoutIcon />
                Выйти
            </Button> */}
        </Container>
    );
};
