import { Button, Container, Typography } from '@/ui-kit';
import styles from './liked-list.module.scss';
import ImageMockSrc from '@/assets/images/mock/image-thumb.jpg';
import { MagicStick } from '@/assets/icons';
import { routesApp } from '@/constans/routes';
import { useAuth } from '@/store/useAuth';
import { Profile } from '@/types/profile';
import { LikedItem } from './componenets/liked-item';
import { showToast } from '@/utils/show-toast';
import { VoteProfileDto } from '@/api/profiles';
import { useVoteProfileById } from '@/hooks/use-vote-profile-by-id';
import React from 'react';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

interface Props {
    profiles: Profile[];
    refetchData?: () => void;
}

export const LikedList: React.FC<Props> = ({ profiles = [], refetchData }) => {
    const navigate = useNavigate();
    const userData = useAuth((state) => state.getUser());
    const { mutateAsync: voteProfileById } = useVoteProfileById();
    const handleVoteProfileById = async (profileId: number, voteProfileDto: VoteProfileDto) => {
        try {
            const { data } = await voteProfileById({
                profileId,
                voteProfileDto,
            });

            if (voteProfileDto.vote === 'dislike') {
                refetchData?.();
            }

            if (voteProfileDto.vote === 'like') {
                navigate(generatePath(routesApp.chat, { id: String(data?.id) }));
            }

            showToast('success', voteProfileDto.vote === 'like' ? 'Лайк поставлен' : 'Дизлайк поставлен');
        } catch (e) {
            showToast('error', 'Произошла ошибка');
        }
    };
    const isPaid = userData?.is_paid;

    const noLikeYet = (
        <div className={styles.noLike}>
            <div className={styles.noLike__dekor} />
            <Typography
                className={styles.noLike__title}
                center
                fontWeight="semibold"
                fontFamily="SFProText"
            >
                У вас пока нет лайков
            </Typography>

            <Typography
                className={styles.noLike__titleText}
                center
                fontWeight="semibold"
                fontFamily="SFProText"
            >
                Лайкайте профили и совсем скоро тебя найдёт кто-то особенный!
            </Typography>

            <Button
                className={styles.noLike__button}
                isLink
                to={routesApp.browse}
                typeColor="orange"
                textColor="white"
            >
                Найти пару
            </Button>
        </div>
    );

    const profileList =
        profiles.length > 0
            ? profiles.map((profile) => (
                  <LikedItem
                      img={profile.photos.length > 0 ? profile.photos[0].thumb_url : null}
                      key={profile.id}
                      profile={profile}
                      onVoteProfile={(vote) => handleVoteProfileById(profile.id, { vote })}
                      isPaid={isPaid || false}
                  />
              ))
            : noLikeYet;

    return (
        <Container className={styles.container}>
            {!isPaid && <div className={styles.dekorHeart} />}
            {!isPaid && (
                <Typography
                    className={styles.title}
                    center
                    fontWeight="semibold"
                    fontFamily="SFProText"
                >
                    Узнай, кому ты нравишься
                </Typography>
            )}
            {!isPaid && (
                <>
                    <Typography
                        center
                        className={styles.text}
                        fontWeight="normal"
                        fontFamily="SFProText"
                    >
                        Смотрите анкеты тех, кому вы понравились
                        <br />и отвечайте взаимностью
                    </Typography>
                    <Button
                        typeColor="orange"
                        variant="stretched"
                        className={styles.button}
                        isLink
                        to={routesApp.profile}
                    >
                        <span>Активировать Premium</span>
                        <MagicStick
                            width={20}
                            height={20}
                        />
                    </Button>
                </>
            )}
            {/*{!isPaid ? (*/}
            {/*    <div className={styles.list}>*/}
            {/*        {Array.from({ length: 5 }).map((_, index) => (*/}
            {/*            <LikedItem*/}
            {/*                img={ImageMockSrc}*/}
            {/*                key={index}*/}
            {/*                isPaid={false}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*) : (*/}
            {profileList}
            {/*)}*/}
        </Container>
    );
};
