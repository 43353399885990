import { Typography } from '@/ui-kit';
import { MessageChat } from '../message-chat';
import styles from './messages-list.module.scss';
import { Chat } from '@/types/chat';
import { SkeletonMessagesList } from './skeleton-messages-list';
import { EmptyMessages } from '../empty-messages/';
import { EmptyCouples } from '../empty-couples';

interface Props {
    chatList?: Chat[];
    isLoading: boolean;
    isEmptyMessage?: boolean;
}

export const MessagesList: React.FC<Props> = ({ chatList, isLoading, isEmptyMessage }) => {
    return (
        <div className={styles.container}>
            <div className={styles.label}>
                <Typography
                    typeColor="gray"
                    uppercase
                    variant="span"
                >
                    Чаты
                </Typography>
            </div>
            <div className={styles.messagesList}>
                {isLoading ? (
                    <SkeletonMessagesList />
                ) : chatList && chatList.length > 0 ? (
                    chatList?.map((chat) => (
                        <MessageChat
                            chat={chat}
                            key={chat.profile.id}
                        />
                    ))
                ) : (
                    <EmptyMessages
                        title="У вас есть новые пары"
                        text="Начните общение прямо сейчас"
                    />
                )}
            </div>
            {isEmptyMessage && <EmptyCouples />}
        </div>
    );
};
