import { useQuery } from '@tanstack/react-query';

import { authApi } from '@/api';

export const useGetProfile = () => {
    return useQuery({
        queryKey: ['profile'],
        queryFn: authApi.getProfile,
        refetchOnWindowFocus: false,
        retry: 1,
    });
};
