import { SettingsItem } from './components/settings-item';
import { dataSettingsRoutes } from './data-settings-routes';
import styles from './settings-list.module.scss';

export const SettingsList = () => {
    return (
        <div className={styles.settingsList}>
            {dataSettingsRoutes.map((item) => {
                const IconComponent = item.icon;
                return (
                    <SettingsItem
                        key={item.value}
                        path={item.path}
                        icon={
                            <IconComponent
                                width={28}
                                height={28}
                            />
                        }
                        title={item.title}
                    />
                );
            })}
        </div>
    );
};
