import { Avatar, Typography } from '@/ui-kit';
import styles from './message-chat.module.scss';
import { Chat } from '@/types/chat';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { routesApp } from '@/constans/routes';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { format } from 'date-fns';

interface Props {
    chat: Chat;
}

export const MessageChat: React.FC<Props> = ({ chat }) => {
    const isNewMessage = false;

    return (
        <div className={styles.messageChat}>
            <div className={styles.avatar}>
                <Avatar
                    size={61}
                    src={chat.profile?.photos[0]?.thumb_url}
                    alt={chat.profile?.public_name ?? 'avatar'}
                />
                {/* {chat.status === 'online' && <span className={styles.status}></span>} */}
            </div>
            <div className={styles.wrapInfo}>
                <Link
                    to={generatePath(routesApp.chat, { id: String(chat.profile.id) })}
                    className={styles.link}
                >
                    <div className={styles.info}>
                        <Typography
                            typeColor="brown"
                            className={styles.name}
                        >
                            {chat.profile?.public_name}, {differenceInYearsResult(chat.profile?.dob)}
                        </Typography>
                        {chat.message?.created_at && (
                            <Typography
                                typeColor="gray"
                                className={styles.time}
                            >
                                {format(new Date(chat.message.created_at), 'HH:mm')}
                            </Typography>
                        )}
                    </div>
                    <div className={styles.messageInfo}>
                        <Typography
                            className={styles.lastMessage}
                            typeColor={isNewMessage ? 'orange' : 'gray-light'}
                        >
                            {chat.message?.text ? chat.message.text : ''}
                        </Typography>
                        {/* {isNewMessage && (
                            <div className={styles.countMessage}>
                                <Typography
                                    variant="span"
                                    typeColor="white"
                                    className={styles.count}
                                >
                                    {chat.message.}
                                </Typography>
                            </div>
                        )} */}
                    </div>
                </Link>
            </div>
        </div>
    );
};
