import { ApiClient } from '@/services/api.service';
export interface PhotoUploadDto {
    photo: File;
}
class Photo {
    getAllPhotos() {}

    async uploadPhoto(dataPhoto: FormData) {
        const data = await ApiClient.post<Photo>(`/photos/`, dataPhoto);
        return data;
    }

    async deletePhotoById(id: number) {
        const data = await ApiClient.delete<any>(`/photos/${id}`);
        return data;
    }

    async updatePhoto({ id, dataPhoto }: { id: number; dataPhoto: FormData }) {
        return ApiClient.patch<any>(`/photos/${id}`, dataPhoto);
    }
}

export const photoApi = new Photo();
