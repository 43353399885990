import { authApi } from '@/api';
import { showToast } from '@/utils/show-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
export const useUpdateUserData = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: authApi.updatedProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profile'] });
        },
        onError: (error) => {
            console.error('Error updating user data:', error);
        },
    });

    return mutation;
};
