import { SkeletonMessageChat } from '../message-chat';

type Props = {
    num?: number;
};

export const SkeletonMessagesList: React.FC<Props> = ({ num = 4 }) => {
    return (
        <>
            {Array.from({ length: num }).map((_, index) => (
                <SkeletonMessageChat key={index} />
            ))}
        </>
    );
};
