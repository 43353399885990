import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './typography.module.scss';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label';

interface Props extends React.HTMLAttributes<HTMLElement> {
    variant?: Variant;
    className?: string;
    style?: React.CSSProperties;
    typeColor?: 'white' | 'orange' | 'black' | 'gray' | 'gray-light' | 'brown';
    uppercase?: boolean;
    center?: boolean;
    fontWeight?: 'normal' | 'medium' | 'semibold' | 'bold';
    isError?: boolean;
    fontFamily?: 'SFProDisplay' | 'SFProText';
}

export const Typography: React.FC<PropsWithChildren<Props>> = ({
    variant = 'p',
    className,
    typeColor = 'black',
    style,
    children,
    uppercase,
    center,
    fontWeight = 'normal',
    isError,
    fontFamily = 'SFProDisplay',
    ...rest
}) => {
    // Указываем тип элемента
    const Tag = variant;

    // Сопоставление тегов с классами из модуля
    const variantClasses = {
        h1: styles.h1,
        h2: styles.h2,
        h3: styles.h3,
        h4: styles.h4,
        h5: styles.h5,
        h6: styles.h6,
        p: styles.p,
        span: styles.span,
        label: styles.label,
    };
    const typeColorClasses = {
        white: styles.white,
        orange: styles.orange,
        black: styles.black,
        gray: styles.gray,
        brown: styles.brown,
        'gray-light': styles.grayLight,
    };

    const fontFamilyClasses = {
        SFProDisplay: styles.SFProDisplay,
        SFProText: styles.SFProText,
    };

    const fontWeightClasses = {
        normal: styles.normal,
        medium: styles.medium,
        semibold: styles.semibold,
        bold: styles.bold,
    };

    return (
        <Tag
            className={clsx(
                variantClasses[variant],
                typeColorClasses[typeColor],
                uppercase && styles.uppercase,
                center && styles.center,
                fontWeightClasses[fontWeight],
                fontFamilyClasses[fontFamily],
                isError && styles.error,
                className,
            )}
            style={style}
            {...rest}
        >
            {children}
        </Tag>
    );
};
