import { FunIcon, LoveIcon, NotSpecified } from '@/assets/icons';
import { Profile } from '@/types/profile';
interface DataGoal {
    value: Profile['purpose'];
    illustarion: React.FC;
    text: string;
}
export const dataPurposes: DataGoal[] = [
    { value: 'fun', illustarion: FunIcon, text: 'Новые друзья' },
    { value: 'love', illustarion: LoveIcon, text: 'Романтические отношения' },
    // { value: 'not_specified', illustarion: NotSpecified, text: 'Как пойдет' },
];
