import { Container } from '@/ui-kit';
import styles from './premium-list.module.scss';
import { PremiumItem } from './components/premium-item';
import { Tariff } from '@/types/tariff';
interface Props {
    tariffs?: Tariff[];
    tariffActiveId?: number;
    onChangeTariff: (id: number) => void;
}
export const PremiumList: React.FC<Props> = ({ tariffs, tariffActiveId, onChangeTariff }) => {
    return (
        <Container className={styles.container}>
            <div className={styles.premiumList}>
                {tariffs?.map((tariff) => (
                    <PremiumItem
                        onClick={() => onChangeTariff(tariff.id)}
                        key={tariff.id}
                        price={tariff.amount}
                        date={tariff.title}
                        isActive={tariff.id === tariffActiveId}
                    />
                ))}
            </div>
        </Container>
    );
};
