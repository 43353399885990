import React from 'react';
import styles from './last-card-questionnaire.module.scss';
import Emoji from '@/assets/images/emoji-last-card.png';
import { Button, Typography } from '@/ui-kit';

interface Props {
    onClickOpenFilter: () => void;
}

export const LastCardQuestionnaire: React.FC<Props> = ({ onClickOpenFilter }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.emoji}>
                    <img
                        src={Emoji}
                        width={56}
                        height={56}
                        alt="Emoji"
                    />
                </div>
                <Typography
                    fontWeight="bold"
                    typeColor="white"
                    className={styles.title}
                    fontFamily="SFProText"
                    center
                >
                    Анкеты закончились
                </Typography>
                <Typography
                    typeColor="white"
                    fontFamily="SFProText"
                    center
                    className={styles.text}
                    fontWeight="normal"
                >
                    Попробуйте сменить фильтры поиска или&nbsp;зайдите позже
                </Typography>
                <Button
                    onClick={onClickOpenFilter}
                    className={styles.button}
                    textColor="black"
                    typeColor="white"
                >
                    Открыть фильтр
                </Button>
            </div>
        </div>
    );
};
